import { AlexaProvider } from './utils/AlexaService';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SplashTutorial from './views/SplashTutorial';
import './css/App.css';
import FallingStars from './views/FallingStars';
// import BackgroundFlyers from './views/BackgroundFlyer';

function App() {
  return (
    <>
      <FallingStars/>
      {/* <BackgroundFlyers/> */}
      <AlexaProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SplashTutorial />} />
          </Routes>
        </Router>
      </AlexaProvider>

    </>
  );
}

export default App;
