import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import '../css/UpsellModal.css';
// import other images here if needed
import gameModal from '../img/assets/upsell-modal.png';
import redCross from '../img/assets/new-modals/red-cross.png';

const UpsellModal = ({ setUpsellModal = () => {}, locale = 'en-US' }) => {

    // Function to preload images
    useEffect(() => {
        const preloadImage = (src) => {
            const img = new Image();
            img.src = src;
        };

        // Preload images
        preloadImage(gameModal);
        preloadImage(redCross);
    }, []); // Empty array ensures this only runs once when the component mounts

    function closeModal(event) {
        if (event) {
            const button = event.currentTarget;
            button.classList.add('alien-action-button-pressed');

            setTimeout(() => {
                button.classList.remove('alien-action-button-pressed');
                $(".modal-container-bg").fadeOut(200)
                setTimeout(() => {
                    setUpsellModal(false);
                }, 200);
            }, 200); // Duration to match the CSS transition
        }
    }

    return (
        <div className='modal-container-bg'>
            <div className="modal-container">
                <img src={gameModal} alt="Game Modal" className="modal-background" />
                <img className="modal2-close-button" src={redCross} onClick={closeModal} alt="Close" />
            </div>
        </div>
    );
};

export default UpsellModal;
