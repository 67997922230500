import React, { useEffect, useMemo } from 'react';

const Star = ({ id, top, left }) => {
  const starStyle = {
      position: 'absolute',
      top: `${top}%`,
      left: `${left}%`,
      width: '5px', // Or any size
      height: '5px', // Or any size
      background: 'white',
      borderRadius: '50%',
      boxShadow: '0 0 20px white', // Optional: Adds a glow effect
      animation: 'pulse 2s infinite ease-in-out',
      animationDelay: `${Math.random() * 2}s`, // Random delay
  };

  return <div id={id} style={starStyle}></div>;
};

const doesOverlap = (newStar, existingStars, minDistance) => {
  for (let star of existingStars) {
      const distance = Math.sqrt(
          (star.top - newStar.top) ** 2 + (star.left - newStar.left) ** 2
      );
      if (distance < minDistance) {
          return true;
      }
  }
  return false;
};

const numberOfStars = 100; // Adjust the number of stars here
const minDistance = 3; // Minimum distance between stars, adjust as needed

const stars = [];
const existingStars = [];
for (let i = 0; i < numberOfStars; i++) {
  let top, left, newStar, overlap;
  do {
    top = Math.random() * 100;
    left = Math.random() * 100;
    newStar = { top, left };
    overlap = doesOverlap(newStar, existingStars, minDistance);
  } while (overlap);

  existingStars.push(newStar);
  stars.push(<Star key={i} id={`star${i}`} top={top} left={left} />);
}

const FallingStars = () => {

  return <div className="falling-stars-container" >{stars}</div>;
};

export default FallingStars;