import React, { useState, useImperativeHandle, forwardRef } from 'react';

const AlienAnim = forwardRef(({ initialGif }, ref) => {
  const [alienGif, setAlienGif] = useState(initialGif);
  const [isTalking, setIsTalking] = useState(false); // Track if the GIF is talking

  // Use useImperativeHandle to expose setAlienGif and isTalking to the parent
  useImperativeHandle(ref, () => ({
    changeGif(newGif, isTalking = false) {
      setAlienGif(newGif);
      setIsTalking(isTalking); // Set if the new GIF is the talking one
    }
  }));

  return (
    <img 
      src={alienGif} 
      draggable="false" 
      alt="Idle Alien" 
      className={`centered-gif`} 
    />
  );
});

export default AlienAnim;
