import SplashAnim from './SplashAnim';
import StarryBg from '../img/assets/starry-bg.jpg';
import '../css/LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <img src={StarryBg} alt="Starry Background" className="starry-bg" />
      <SplashAnim />
    </div>
  );
}

export default LoadingScreen;
