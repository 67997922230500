import React from 'react';
import TextBox from './TextBox'; // Your TextBox component
import '../css/TextBoxList.css'; // CSS for styling the list

const TextBoxList = ({ textBoxItems }) => {
    const shouldFadeOut = textBoxItems.length >= 3;
  
    return (
      <div className="text-box-list">
        {textBoxItems.map((item, index) => (
          <div key={index} className={shouldFadeOut && index <  3 ? 'fade-out' : ''}>
            <TextBox message={item.message} user={item.user} />
          </div>
        ))}
      </div>
    );
  };
  

export default TextBoxList;
