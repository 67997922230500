import React, { createContext, useEffect, useState, useRef } from 'react';
export const AlexaContext = createContext(null);

const timeToDie = 60000;//28800000;

export const AlexaProvider = ({ children }) => {
  // const [log, setLog] = useState('Initializing...'); // set initial log message
  const [message, setMessage] = useState({});
  const [gameData, setGameData] = useState({});
  const sendMessage  = useRef(() => {});
  const requestMicrophoneOpen = useRef(null);

  useEffect(() => {
    window.Alexa.create({ version: '1.1' })
      .then((args) => {
        const {
          alexa,
          message
        } = args;

        // Play audio in here. 
        // On finished, switch to a diff screen
        // Message comes in when the Alexa skill first connects to the website
        setGameData(
          {
             locale: message.locale,
             messages: message.messages || 0,
             fillLevel: message.fillLevel,
             level: message.level || 1,
             justPurchased: message.justPurchased,
             isFirstPlay: message.isFirstPlay,
             isReturnPlay: message.isReturnPlay,
             launchUpsell: message.launchUpsell,
             textBoxData: message.textBoxData,
            lockedAnimations: message.lockedAnimations || [
              "moonwalk", 
              "tango", 
              "poop", 
              "sleep", 
              "juggle", 
              "meditate", 
              "jump", 
            ],
            memories: message.memories || [],
            customIntro: message.customIntro
          }
        );



        // When new messages are sent (i.e. the player interact with the Alexa skill), then they will be received and the state will be updated.

        alexa.skill.onMessage((message) => {
          // document.getElementById("testing").innerHTML = JSON.stringify(message);
          setMessage(message);
        });

        // Send a message back to alexa.
        sendMessage.current = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        }

        // Open the players microphone, so they can give a response without saying "alexa"
        requestMicrophoneOpen.current = (config) => {
          alexa.voice.requestMicrophoneOpen(config);
        };

        window.sendMessage = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        }

      })
      .catch(error => {
        // window.location.href = "https://hugo.fm";
        console.log(`Alexa failed to initialise. `);
      });
  }, []);

  return (
    <AlexaContext.Provider value={
      { 
        message, 
        gameData, 
        setGameData, 
        sendMessage,
        setMessage, 
        requestMicrophoneOpen: requestMicrophoneOpen.current, // Add this line
      }}>
      {children}
    </AlexaContext.Provider>
  );
};
