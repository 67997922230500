import React, { useState, useEffect, forwardRef, useRef } from 'react';
import lottie from 'lottie-web';

const Loading = ({isLoading=false}) => {
    const animationRef = useRef(null);
    const [animationInstance, setAnimationInstance] = useState(null);
    const animationData = require('../lottie/loading.json');
    const anim = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
    });
    useEffect(() => {
        if (isLoading && animationRef.current && !animationInstance) {
            setAnimationInstance(anim);
        }

        return () => {
            if (animationInstance) {
                animationInstance.destroy();
                setAnimationInstance(null);
            }
        };
    }, [isLoading]);

    return (
        isLoading && <div className="game-over">
            <div ref={animationRef} style={{ transform: 'scale(2)', transformOrigin: 'center' }}/>
        </div>
    );
};

export default Loading;
