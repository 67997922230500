var alienGameSource = null;
var activeSources = []; // Array to keep track of all active audio sources

const playAudio = (audio) => {
    let cleanupCalled = false;

    const play = () => {
        if (audio) {
            audio.play().catch((error) => {
                console.error('Playback error:', error);
            });

            audio.onended = () => {
                if (!cleanupCalled) {
                    cleanupCalled = true;
                    cleanupAudio();
                }
            };
        }
    };

    const cleanupAudio = () => {
        if (audio) {
            audio.src = '';
            audio.onended = null;
            audio = null;
        }
    };

    return {
        play,
        cleanup: cleanupAudio,
    };
};

const audioContext = new AudioContext();

function playWithIncreasedVolume(audioUrl, gainValue, onEndedCallback = null, triggerEarlyCallback = false) {
   // stopAllAudio(); // Stop all playing audio when a new audio starts
    
    fetch(audioUrl)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(audioBuffer => {
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;

            const gainNode = audioContext.createGain();
            gainNode.gain.value = gainValue;

            source.connect(gainNode);
            gainNode.connect(audioContext.destination);

            activeSources.push(source); // Add source to the list of active sources

            if (audioUrl.indexOf('/alien (') !== -1) {
                alienGameSource = source;
            }

            let callbackTimeoutId;
            if (triggerEarlyCallback && onEndedCallback) {
                const duration = audioBuffer.duration;
                const earlyCallbackTime = 2;
                let triggerTime = (duration - earlyCallbackTime) * 1000;

                if (triggerTime < 1000) {
                    triggerTime = 1000;
                }

                callbackTimeoutId = setTimeout(() => {
                    onEndedCallback();
                }, triggerTime);
            }

            source.onended = () => {
                if (callbackTimeoutId) {
                    clearTimeout(callbackTimeoutId);
                }
                source.disconnect();
                gainNode.disconnect();
                // Remove the source from the list of active sources
                const index = activeSources.indexOf(source);
                if (index > -1) {
                    activeSources.splice(index, 1);
                }
                if (!triggerEarlyCallback && onEndedCallback) {
                    onEndedCallback();
                }
            };

            source.start();
        })
        .catch(err => {
            if (onEndedCallback) onEndedCallback(err);
        });
}

function playAudioSequence(audioFiles, gainValue = 2) {
    let currentFileIndex = 0;

    function playFile(index) {
        if (index < audioFiles.length) {
            const currentItem = audioFiles[index];

            if (typeof currentItem === 'number') {
                setTimeout(() => {
                    currentFileIndex++;
                    playFile(currentFileIndex);
                }, currentItem);
            } else {
                playWithIncreasedVolume(currentItem, gainValue, () => {
                    currentFileIndex++;
                    playFile(currentFileIndex);
                });
            }
        }
    }

    playFile(currentFileIndex);
}

// Function to stop all active audio sources
function stopAllAudio() {
    // Stop and disconnect each active audio source
    for (let source of activeSources) {
        source.stop();
        source.disconnect();
    }
    activeSources = []; // Clear the list of active sources

    // Optionally reset any other active nodes if necessary
}

export default {
    playAudio,
    playWithIncreasedVolume,
    playAudioSequence,
    stopAllAudio, // Export the new function
};
