import React, { useEffect } from 'react';
import gameModalBackground from '../img/assets/new-modals/memories.png';
import redCross from '../img/assets/new-modals/red-cross.png';
import '../css/Memory.css';

import ButtonPress from "../audio/button-press.mp3";
import audioFunctions from '../utils/audioFunctions';
import MemoryItem from '../views/MemoryItem';
import AlienSilhouette from '../img/alien/alien-silhouette.png';

const MemoriesModal = ({ toggleModal = () => {}, showMemoryModal, memories = [] }) => {
  // Preload images and audio when the component mounts
  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    const preloadAudio = (src) => {
      const audio = new Audio();
      audio.src = src;
    };

    // Preload all necessary images
    preloadImage(gameModalBackground);
    preloadImage(redCross);
    preloadImage(AlienSilhouette);

    // Preload audio
    preloadAudio(ButtonPress);
  }, []);

  // Function to close the modal
  function closeModal(event) {
    if (event) {
      const button = event.currentTarget;
      button.classList.add('alien-action-button-pressed');
      audioFunctions.playWithIncreasedVolume(ButtonPress, 1, null);
      setTimeout(() => {
        button.classList.remove('alien-action-button-pressed');
        toggleModal(showMemoryModal);
      }, 200); // Duration to match the CSS transition
    }
  }

  return (
    <div className='memories-container-bg'>
      <div className='memories-container'>
        <img className="modal-background" src={gameModalBackground} alt="Settings Background" />
        <img className="modal-close-button" src={redCross} onClick={closeModal} alt="Close" />

        {memories.length ? (
          <div className="memories-list">
            {memories.map((mem) => {
              return <MemoryItem key={mem.id} memoryObj={mem} />;
            })}
          </div>
        ) : (
          <div className='no-memories-text'>
            Chat to make memories!
            <img style={{ height: '25vh' }} src={AlienSilhouette} alt="Alien Silhouette" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MemoriesModal;
