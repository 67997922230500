import SpaceShip from '../img/assets/spaceship.png';
import { AlexaContext } from '../utils/AlexaService';
import {useContext, useEffect, useState} from 'react';
const DayContainer = ({currLevel}) => {
    // Function to make the spaceship fly down
    return <div className='day-container'>
    <div className="day-number">level {currLevel}</div> 
    <img src={SpaceShip} alt="Day Image" className="day-image" />
    <div className="gradient-shape">
        <div className='text-content'>
        UNLOCKED!<span class="tight-br"><br/></span><div className='unlock-action'></div>
        </div>
    </div>
    </div>
}

export default DayContainer;