import React, { useImperativeHandle, forwardRef, useState, useRef, useEffect } from 'react';

import '../css/FallingFood.css';

import Diamond from '../img/navbar/diamond.png';

function getRandomElement(arr) {
  // Generate a random index based on the array length
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

const cakeStyles = [
  { left: '0%', top: '-100px', transform: 'rotate(0deg)' },
  { left: '10%', top: '-50px', transform: 'rotate(30deg)' },
  { left: '20%', top: '-150px', transform: 'rotate(60deg)' },
  { left: '30%', top: '-20px', transform: 'rotate(90deg)' },
  { left: '40%', top: '-180px', transform: 'rotate(120deg)' },
  { left: '50%', top: '-30px', transform: 'rotate(150deg)' },
  { left: '60%', top: '-70px', transform: 'rotate(180deg)' },
  { left: '70%', top: '-100px', transform: 'rotate(210deg)' },
  { left: '80%', top: '-120px', transform: 'rotate(240deg)' },
  { left: '90%', top: '-50px', transform: 'rotate(270deg)' },
];

const DropTheFood = forwardRef((props, ref) => {
  const [foodImage, setFoodImage] = useState(null);
  const [animateCake, setAnimateCake] = useState(false);
  const timeoutRef = useRef(null); // Reference to store the timeout ID

  const dropTheCake = (foodType) => {
    // Dont do random food but do something specific
    setFoodImage(foodType);
    setAnimateCake(true);

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout and store the ID in timeoutRef
    timeoutRef.current = setTimeout(() => {
      setAnimateCake(false);
      setFoodImage(null); // Clear the food image
    }, 3500);
  };

  const cakeClass = `cake ${animateCake ? 'animate' : ''}`;

  useImperativeHandle(ref, () => ({
    dropTheCake,
  }), []);

  // Clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="falling-cakes-viewport" style={{ display: animateCake ? 'block' : 'none' }}>
      <div className="falling-cakes">
        {foodImage && cakeStyles.map((style, index) => (
          <img key={index} src={foodImage} className={cakeClass} alt="Cake" style={style} />
        ))}
      </div>
    </div>
  );
});

export default DropTheFood;
