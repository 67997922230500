import ProgBarShell from '../img/assets/prog-backer-v2.png';
import HappyFace from '../img/assets/smiley-face.png';
import SadFace from '../img/assets/sad-face.png';
import NeutralFace from '../img/assets/neutral-face.png';

import { AlexaContext } from '../utils/AlexaService';
import { useEffect, useContext, useState, useRef} from 'react';

const HappyBar = () => {
    const { gameData} = useContext(AlexaContext);
    let {fillLevel} = gameData;

    if (fillLevel < 0) fillLevel = 0;

    return <div className='outer-happy'>
    <div className="happy-meter-title">FRIEND-O-METER</div>
      <div className='happy-bar'>
        <div className="content-container">
            <img src={ProgBarShell} alt="Overlay" className="happy-meter-overlay" />
              <div className="happy-meter-container">
                <div className='happy-meter-container-props'>
                <div className="happy-meter-fill" style={{ width: `${fillLevel}%`}}></div>
                  <div className="happy-meter-stripes" style={{ width: `${fillLevel}%`}}></div>
                </div>
    
              </div>
            </div>
      </div>
    
    </div>
}

export default HappyBar;