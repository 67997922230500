import React from 'react';
import '../css/TextBox.css'; // Ensure this path is correct

import AlienFace from '../img/assets/smiley-face.png';
import ProfileFace from '../img/assets/profile-face.png';

const TextBox = ({ message, user }) => {
  return (
    <div className={`text-box ${user}`}>
      {user === 'alien' && (
        <div className="alien-header">
          <img src={AlienFace} alt="Happy Alien" className="user-icon alien" />
        </div>
      )}
      {user === 'player' && (
        <div className="player-header">
          <img src={ProfileFace} alt="Player Profile" className="user-icon player" />
        </div>
      )}
      <div className="message-content">{message}</div>
    </div>
  );
};

export default TextBox;
