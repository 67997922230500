import React, { memo, useEffect, useRef } from "react";
import "../css/SplashScreen.css";
import "../css/FallingStars.css";
import "../css/AlienAction.css";

import DropTheFood from "./DropTheFood";
import { useState, useContext } from "react";
import { AlexaContext } from "../utils/AlexaService"

import MoonImage from "../img/assets/moon.png";
import StarAudio from "../audio/vo/star-audio.mp3";
import DayContainer from "./DayContainer";
import FoodResp from "../audio/vo/tutorial/food-resp.mp3";

import DiamondImg from "../img/navbar/diamond.png";
import Diamond from "../img/navbar/diamond-anim.gif";
import audioFunctions from "../utils/audioFunctions";

import HappyBar from "./HappyBar";
import LoseHealth from "../audio/lose-health.mp3";

import Language1 from "../audio/language/1.mp3";
import Language2 from "../audio/language/2.mp3";
import Language3 from "../audio/language/3.mp3";

import FaveAnimal from "../audio/vo/tutorial/fave-animal.mp3";
import ReturnGreeting from "../audio/vo/greetings-1.mp3";




import CrystalFx from "../audio/crystal-shard.mp3";
import Loading from "./Loading";

import MemoriesModal from "./MemoriesModal";

import TextBoxList from "./TextBoxList";
import HelloAgain from "../audio/hello-again.mp3";

import Gibberish1 from "../audio/vo/gibberish/1.mp3";
import Gibberish2 from "../audio/vo/gibberish/2.mp3";
import Gibberish3 from "../audio/vo/gibberish/3.mp3";
import Gibberish4 from "../audio/vo/gibberish/4.mp3";
import Gibberish5 from "../audio/vo/gibberish/5.mp3";
import Increase from "../audio/vo/increase.mp3";


import ButtonPress from "../audio/button-press.mp3";
import NotificationText from "./ScoreNotification";
import Poke1 from "../audio/vo/pokes/1.mp3";
import Poke2 from "../audio/vo/pokes/2.mp3";
import Poke3 from "../audio/vo/pokes/3.mp3";
import Poke4 from "../audio/vo/pokes/4.mp3";
import Poke5 from "../audio/vo/pokes/5.mp3";
import Poke6 from "../audio/vo/pokes/6.mp3";
import Poke7 from "../audio/vo/pokes/7.mp3";
import Poke8 from "../audio/vo/pokes/8.mp3";
import Poke9 from "../audio/vo/pokes/9.mp3";
import Poke10 from "../audio/vo/pokes/10.mp3";
import Poke11 from "../audio/vo/pokes/11.mp3";
import Poke12 from "../audio/vo/pokes/12.mp3";
import Push from "../audio/push.mp3";

import ContactMotherShipVo from "../audio/contact-mothership-v2.mp3";

import LoadingScreen from "../views/LoadingScreen";
import UnlockFx from "../audio/unlock-fx.mp3";
import UnlockIntro from "../audio/unlock-intro-4.mp3";
import BrainBtn from "../img/alien/brain-gif.gif";

import UpsellModal from "./UpsellModal";

import TalkingGif1 from "../img/alien/webp/talk-3.webp";
import IdleAlienGif from "../img/alien/webp/idle-3.webp";

import AlienAnim from "./AlienAnim";

function flyDown() {
  const dayContainer = document.querySelector(".day-container");
  const gradientShape = document.querySelector(".gradient-shape");

  // Clear any previous animations on the gradientShape
  gradientShape.style.animation = "none";

  // Start the flyDown animation for the spaceship
  dayContainer.classList.remove("flyUpAnimation");
  dayContainer.classList.add("flyDownAnimation");
  void gradientShape.offsetWidth;
  gradientShape.style.animation = "appearAndMoveDown 1s forwards"; // Adjust timing as needed

  // Listen for when the flyDown animation ends
  dayContainer.addEventListener(
    "animationend",
    () => {
      setTimeout(() => {
        // Then initiate the flyBack function to start the spaceship's return journey
        flyBack();
      }, 7000); // Delay set to 1000 milliseconds (1 second)
    },
    { once: true },
  );
}

function flyBack() {
  const dayContainer = document.querySelector(".day-container");
  const gradientShape = document.querySelector(".gradient-shape");

  // Start the flyUp animation for the spaceship
  dayContainer.classList.remove("flyDownAnimation");
  dayContainer.classList.add("flyUpAnimation");

  // Note: If you want to start the disappear animation only after the spaceship has flown up,
  // you might want to move this inside an 'animationend' listener like in flyDown.
  // Otherwise, the gradient might start disappearing immediately upon triggering flyBack.

  // Set up the listener for when the flyUp animation ends
  dayContainer.addEventListener(
    "animationend",
    () => {
      // Trigger reflow to ensure the next animation can start properly
      void gradientShape.offsetWidth;

      // Start the gradientShape disappearance animation
      gradientShape.style.animation = "disappearAndMoveUp 2s forwards";

      // const textContentDiv = document.querySelector('.text-content');
      // textContentDiv.innerHTML = "";
    },
    { once: true },
  );
}

const ALL_GIBB = [Gibberish1, Gibberish2, Gibberish3, Gibberish4, Gibberish5];

const handlePress = (event) => {
  const button = event.currentTarget;
  button.classList.add("alien-action-button-pressed");

  setTimeout(() => {
    button.classList.remove("alien-action-button-pressed");
  }, 200); // Duration to match the CSS transition
};

const CRYSTAL_SPEECH =
  "Everytime you talk with me, it will use a translator crystal. These are magical crystals that translate my alien speech to human language. So, what do you wanna know? ";
const TANGO_SPEECH = `Oh lalala! That was some good tangoing. ${CRYSTAL_SPEECH}`;



const AllPokes = [Poke1,Poke2,Poke3,Poke4,Poke5,Poke6,Poke7,Poke8,Poke9,Poke10,Poke11,Poke12,];

function toggleHappyMeterColors() {
  // Define the elements by their class names
  const stripes = document.querySelector(".happy-meter-stripes");
  const fill = document.querySelector(".happy-meter-fill");
  const outerHappy = document.querySelector(".outer-happy");

  if (outerHappy) {
    outerHappy.classList.add("outer-happy-pulse");
  }

  // Define the gold color and original colors
  const goldColor = "#FFD700";
  const greenColor = "#32CD32"; // Light green color
  const darkGreenColor = "#006400"; // Dark green color
  // Change colors to gold
  if (stripes && fill) {
    stripes.style.background = `repeating-linear-gradient(
      -45deg,
      ${greenColor},
      ${greenColor} 30px,
      ${darkGreenColor} 30px,
      ${darkGreenColor} 60px
    )`;
    fill.style.backgroundColor = goldColor;

    // Set a timeout to revert the colors back to original after 2 seconds
    setTimeout(() => {
      const originalStripeColor = "#fd8514"; // Original stripe color
      const originalFillColor = "#ffc500"; // Original fill color
      stripes.style.background = `repeating-linear-gradient(
        -45deg,
        ${originalStripeColor},
        ${originalStripeColor} 30px,
        transparent 30px,
        transparent 60px
      )`;
      fill.style.backgroundColor = originalFillColor;

      if (outerHappy) {
        outerHappy.classList.remove("outer-happy-pulse");
      }
    }, 2000); // 2000 milliseconds = 2 seconds
  }
}

function getRandomElement(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}


function pulseButton() {
  const button = document.querySelector(".settings-button");
  button.style.animation = "settings-pulse 0.6s ease-in-out"; // Duration should match the CSS animation

  // Remove the animation property after it completes to allow re-application
  button.addEventListener(
    "animationend",
    () => {
      button.style.animation = "";
    },
    { once: true },
  ); // Use { once: true } to ensure the event is only handled once
}

const SplashScreen = () => {
  const { message, gameData, setGameData, setMessage } = useContext(AlexaContext);
  const [foodType, setFoodType] = useState({});
  const dropFoodRef = useRef(null);
  const discoBallRef = useRef(null);

  const [toggleUpsell, setUpsellModal] = useState(false);
  const [doLoading, setDoLoading] = useState(false);

  const buttonsDisabled = useRef(false);

  const notifRef = useRef(null);

  const [showGamePlay, setShowGamePlay] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [currLevel, setCurrLevel] = useState(1);
  const [showMemoryModal, setToggleMemoryModal] = useState(false);
  const [memories, setMemories] = useState([]);
  const [textBoxListItems, setTextBoxListItems] = useState([]);

  const alienAnimRef = useRef(); // Create a ref to control the AlienAnim component

  // Function to change the GIF using the ref
  const switchGif = (anotherGif, isTalking = false) => {
    if (alienAnimRef.current) {
      alienAnimRef.current.changeGif(anotherGif, isTalking); // Change the GIF
    }
  };


  useEffect(() => {
    if (textBoxListItems.length >= 3) {
      // Wait for the fade effect to complete before removing items
      setTimeout(() => {
        setTextBoxListItems(textBoxListItems.slice(2));
      }, 1000); // Assuming your fade effect lasts 1 second
    }
  }, [textBoxListItems]);

  function dropTheDiamonds() {
    setFoodType({ img: DiamondImg });
    // dropFoodRef.current.dropTheCake();
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function enableAllButtons() {
    buttonsDisabled.current = false;
    const allButtons = document.querySelectorAll(".alien-action-button");
    allButtons.forEach((button) => {
      button.disabled = false;
      button.style.opacity = "1"; // Revert opacity to normal
      // Revert any other styles if you've added in disableAllButtons
    });
  }

  function promptUser(overrides = {}) {
    window.sendMessage({
      ALIEN_OPEN_PROMPT: true,
      ...overrides,
    });
  }

  function disableAllButtons() {
    buttonsDisabled.current = true;
    const allButtons = document.querySelectorAll(".alien-action-button");
    allButtons.forEach((button) => {
      button.disabled = true;
      button.style.opacity = "0.5"; // Reduce opacityf to grey out
      // You can add more styles here if needed
    });
  }

  useEffect(() => {
    if (gameData.isReturnPlay && !gameData.launchUpsell) {
      // At this point, if we have a memory speech then we should send an event back to the skill
      if (gameData.customIntro) {
        setGameData({
          ...gameData,
          customIntro: false,
          isReturnPlay: false,
        });
        // Then send a message back to do a cust intro
        window.sendMessage({
          CUSTOM_INTRO: true,
        });
      } else {
        switchGif(TalkingGif1, true);

        setTextBoxListItems([
          {
            user: "alien",
            message: "Greetings Earthling! You can ask me anything. ",
          },
        ]);

        audioFunctions.playWithIncreasedVolume(ReturnGreeting, 2,
          () => {
          // Then here we need to play somethin
          switchGif(IdleAlienGif);
          setGameData({
            ...gameData,
            isReturnPlay: false,
            customIntro: false,
          });
          promptUser();
        });
      }
    }

    if (gameData.isFirstPlay) {
      switchGif(TalkingGif1, true);

      setTextBoxListItems([gameData?.textBoxData]);

      // Do the Alien contact?
      // Set the message data
      audioFunctions.playWithIncreasedVolume(
        FaveAnimal,
        2,
        () => {
          // Then here we need to play somethin
          switchGif(IdleAlienGif);
          setGameData({
            ...gameData,
            isFirstPlay: false,
            isTutorialMode: true,
          });
          promptUser();
          // window.sendMessage(
          //   {
          //     GREETINGS_COMPLETE: true,
          //   }
          // )
        },
        true,
      );
    }

    if (gameData.launchUpsell) {
      setTimeout(() => {
        setMessage({
          SHOW_UPSELL: true,
        });
      }, 1375);
    }

    if (gameData.justPurchased) {
      // This property exists. Lets wait and then do the tts
      setTextBoxListItems([
        {
          message: "Hello again human, you can ask me anything. ",
          user: "alien",
        },
      ]);
      setTimeout(() => {
        setGameData({
          ...gameData,
          justPurchased: false,
        });
        setMessage({
          JUST_PURCHASED: true,
        });
      }, 1000);
    }

    if (gameData.level) {
      setCurrLevel(gameData.level);
    }

    if (gameData.memories) {
      setMemories(gameData.memories);
    }
  }, [gameData]);

  function showNotification() {
    notifRef.current.showNotif();
  }


  var t1, timeOut2
  useEffect(() => {
    audioFunctions.playWithIncreasedVolume(StarAudio, 1, () => {});

    t1 = setTimeout(() => {
      setShowGamePlay(true);
    }, 1000);

    timeOut2 = setTimeout(() => {
      setShowLoading(false);
    }, 4000);

    return () => {
      clearTimeout(t1);
      clearTimeout(timeOut2);
    };
  }, []);

  function pressPoke(fromSkill = false) {
    if (!buttonsDisabled.current) {
      let poke;
      let animationDuration = 1000; // Duration of the animation in milliseconds

      if (gameData.messages > 0) {
        poke = getRandomElement(AllPokes);
      } else {
        poke = getRandomElement(ALL_GIBB);
      }

      disableAllButtons();
      switchGif(TalkingGif1);

      // Add the poke-animation class to start the animation
      document.querySelector(".centered-gif").classList.add("poke-animation");

      audioFunctions.playWithIncreasedVolume(Push, 2, () => {});

      audioFunctions.playWithIncreasedVolume(poke, 2, () => {
        enableAllButtons();
        increaseFill(true);
        switchGif(IdleAlienGif);

        if (fromSkill) {
          promptUser();
        }
        // Reduce the fill level
      });

      // Remove the poke-animation class to stop the animation after duration
      setTimeout(() => {
        document.querySelector(".centered-gif").classList.remove("poke-animation");
      }, animationDuration);
    }
  }

  const doMemoryToggle = (event) => {
    if (event) {
      handlePress(event);
    }
    audioFunctions.playWithIncreasedVolume(ButtonPress, 1.5, () => {});
    setToggleMemoryModal(!showMemoryModal);
  };



  const toggleModal = (event) => {
    if (event) {
      handlePress(event);
    }

    audioFunctions.playWithIncreasedVolume(ButtonPress, 1.5, () => {});
    // Conversation mode
    // Also play the SFX

    // It wasn't open, now it is
    if (!toggleUpsell) {
      window.sendMessage({
        UPSELL: true,
      });
    }

    setUpsellModal(!toggleUpsell);
  };

  const increaseFill = (decrease = false) => {
    if (decrease) {
      // Then we reduce happiness

      if (gameData.fillLevel > 0) {
        setGameData({
          ...gameData,
          fillLevel: gameData.fillLevel - 5,
        });
      }

      var elements = document.getElementsByClassName(
        "notification-text-container",
      );
      if (elements.length > 0) {
        var element = elements[0]; // Assuming you want to target the first matched element
        element.style.color = "red"; // Change the text color to green
        element.innerHTML = "-5"; // Change the text to MAX

        // Set a timeout to change the text back to +5 after 2 seconds
        setTimeout(() => {
          element.innerHTML = "+10"; // Change it back to +5
          element.style.color = "#FFD700"; // Change the text color to green
        }, 3000);
      }

      audioFunctions.playWithIncreasedVolume(LoseHealth, 1.2, () => {});
      showNotification();
    } else if (gameData.fillLevel < 100) {
      // NEed to do eating popup here
      //
      setGameData({
        ...gameData,
        fillLevel: gameData.fillLevel + 10,
      });

      if (gameData.fillLevel < 100) {
        audioFunctions.playWithIncreasedVolume(Increase, 1.2, () => {});
      }

      //
      if (gameData.fillLevel + 10 >= 100) {
        // LEVLE UP!!!!
        var elements = document.getElementsByClassName(
          "notification-text-container",
        );
        if (elements.length > 0) {
          var element = elements[0]; // Assuming you want to target the first matched element
          element.style.color = "green"; // Change the text color to green
          element.innerHTML = "LEVEL UP!"; // Change the text to MAX

          // Level up sp lets do flyUp flyDown

          // Set a timeout to change the text back to +5 after 2 seconds
          setTimeout(() => {
            element.innerHTML = "+10"; // Change it back to +5
            element.style.color = "#FFD700"; // Change the text color to green
          }, 3000);
        }
        toggleHappyMeterColors();
      }
      showNotification();
    } else {
      var elements = document.getElementsByClassName(
        "notification-text-container",
      );
      if (elements.length > 0) {
        var element = elements[0]; // Assuming you want to target the first matched element
        element.style.color = "green"; // Change the text color to green
        // Set a timeout to change the text back to +5 after 2 seconds
        setTimeout(() => {
          element.style.color = "#FFD700"; // Change the text color to green
        }, 3000);
      }
      toggleHappyMeterColors();

      // audioFunctions.playWithIncreasedVolume(
      //   Max,
      //   1.2,
      //   () => {}
      // )
    }
  };

  function buttonIncreaseFill(isSpeech, sendNow = false, memories = []) {
    enableAllButtons();
    increaseFill();
    let newFill = Math.min(gameData.fillLevel + 10, 100);

    let fillObj = {
      ...gameData,
      fillLevel: newFill,
      messages: gameData.messages - 1, // prefer this since i trust handling the counts in lambda function more
    };

    let killTut = false;
    let sendObj = {
      FILL_LEVEL: fillObj.fillLevel,
      KILL_TUT: killTut,
    };

    if (!isSpeech && gameData.isTutorialMode) {
      // Its a button press. Check for tutorial mode
      fillObj.isTutorialMode = false;
      killTut = true;
    }
    // Then we reset and start a new level.
    // gameData.fillLevel + 10 >= 100
    if (gameData.fillLevel + 10 >= 100) {
      // Its a new level!!!!
      // set the new available buttons (if we've levelled up)
      // fly down, play some audio and fly up again
      let updateObj = {
        ...fillObj,
        fillLevel: 5,
        level: gameData.level + 1,
      };

      if (memories.length) {
        updateObj.memories = memories;
      }

      let unlock;

      // flyDown();
      audioFunctions.playWithIncreasedVolume(UnlockFx, 1, () => {});
      // There are locked animation. Lets unlock one of them

      const textContentDiv = document.querySelector(".text-content");
      textContentDiv.innerHTML = "";
      // if (gameData.lockedAnimations?.length) {
      //   unlock = gameData.lockedAnimations.shift(); // Find the matching title
      //   updateObj.lockedAnimations = gameData.lockedAnimations;
      //   // For the unlocked we need to set it in the visual
      //   const textContentDiv = document.querySelector(".unlock-action");
      //   // Set the HTML content
      //   // let matchingObj = BUTTON_DATA.find((x) => x.action === unlock) || {};

      //   textContentDiv.innerHTML = "";
      // } else {
      //   const textContentDiv = document.querySelector(".text-content");
      //   textContentDiv.innerHTML = "";
      // }

      setTimeout(() => {
        setGameData(updateObj);
      }, 2000);

      sendObj.UPDATE_LEVEL = gameData.level + 1;
      sendObj.FILL_LEVEL = 5;

      if (unlock) {
        sendObj.UNLOCK = unlock;
      }
    } else {
      if (memories.length) {
        fillObj.memories = memories;
      }
      setGameData(fillObj);
    }

    if (sendNow) {
      window.sendMessage(sendObj);
    }

    // Window send emssage this
    return sendObj;
  }


  useEffect(() => {
    if (foodType.img && dropFoodRef.current) {
      dropFoodRef.current.dropTheCake(foodType.img);
    }
  }, [foodType]);


  useEffect(() => {

    if (message.message === "CUSTOM_INTRO") {
      // Then we should do the alien animation
      if (message.defaultMessage) {
        disableAllButtons();
        switchGif(TalkingGif1, true);
        setTextBoxListItems([
          ...textBoxListItems,
          {
            user: "alien",
            message: message.display,
          },
        ]);

        audioFunctions.playWithIncreasedVolume(ReturnGreeting, 2, () => {
          // Then here we need to play somethin
          switchGif(IdleAlienGif);
          setGameData({
            ...gameData,
            isReturnPlay: false,
          });
          promptUser();
        });
      } else {
        disableAllButtons();
        switchGif(TalkingGif1, true);

        setTextBoxListItems([
          ...textBoxListItems,
          {
            user: "alien",
            message: message.display,
          },
        ]);

        audioFunctions.playWithIncreasedVolume(
          message.url,
          4,
          () => {
            setTimeout(() => {
              switchGif(IdleAlienGif);
            }, 2000)
            promptUser();
          },
          true,
        );
      }
    }

    if (message.message === "CONTACTING_MOTHERSHIP") {
      window.sendMessage({ ALIEN_CONTACT: message.speech });
      // dropTheDiamonds();
      setTextBoxListItems([
        ...textBoxListItems,
        {
          user: "player",
          message: capitalizeFirstLetter(message.speech),
        },
      ]);

      audioFunctions.playWithIncreasedVolume(ContactMotherShipVo, 1, () => {});

      switchGif(IdleAlienGif);
    }

    if (message.message === "_ALIEN_SPEECH") {
      // Then we should do the alien animation
      disableAllButtons();
      switchGif(TalkingGif1, true);
      pulseButton();
      audioFunctions.playWithIncreasedVolume(CrystalFx, 1, () => {});

      let updateObj = {
        ...gameData,
        messages: message.messageCount, // prefer this since i trust handling the counts in lambda function more
      };

      if (message.memories) {
        // We have new memories, lets attach them
        // flyDownMemories();
        // audioFunctions.playWithIncreasedVolume(
        //   NewMemoryFx, 1
        // );

        setMemories(message.memories);
        updateObj.memories = message.memories;
      }

      setGameData(updateObj);

      setTextBoxListItems([
        ...textBoxListItems,
        {
          user: "alien",
          message: message.display,
        },
      ]);

      // Incoming speech, we can use speak at increased volume and pwhen its finished we
      // Can stop it from playing
      // It will be a URL
      // Check if they have ran out of crystals. If they haven't received bonus crystal, we can award it?
      audioFunctions.playWithIncreasedVolume(
        message.url,
        4,
        () => {

          setTimeout(() => {
            switchGif(IdleAlienGif);
          }, 2000)
          let sendObj = buttonIncreaseFill(true, false, updateObj.memories);
          promptUser(sendObj);
        },
        true,
      );
    }

    if (message.JUST_PURCHASED) {
      switchGif(TalkingGif1);

      audioFunctions.playWithIncreasedVolume(HelloAgain, 2, () => {
        switchGif(IdleAlienGif);
        promptUser();
      });
    }

    if (message.SHOW_UPSELL) {
      // Lets play gibberrish
      let randomGib = getRandomElement(ALL_GIBB);

      ////
      setTextBoxListItems([
        ...textBoxListItems,
        {
          message: "👽👽👽👽👽👽",
          user: "alien",
        },
      ]);

      switchGif(TalkingGif1);
      audioFunctions.playWithIncreasedVolume(randomGib, 2, () => {
        switchGif(IdleAlienGif);
        setTimeout(() => {
          window.sendMessage({
            UPSELL_SPEECH: true,
          });
          // setUpsellModal(true);
        }, 750);
      });
    }

    if (message.HIDE_UPSELL) {
      setUpsellModal(false);
    }


    if (message.TUTORIAL_FOOD) {
      // Before we play animal speech we need to contact the mothership
      switchGif(IdleAlienGif);
      setTextBoxListItems([
        ...textBoxListItems,
        {
          user: "player",
          message: capitalizeFirstLetter(message.PLAYER_FOOD),
        },
      ]);

      // dropTheDiamonds();

      audioFunctions.playWithIncreasedVolume(ContactMotherShipVo, 1, () => {
        switchGif(TalkingGif1, true);
        setTextBoxListItems([
          {
            user: "alien",
            message: capitalizeFirstLetter(message.ALIEN_FOOD),
          },
        ]);

        audioFunctions.playWithIncreasedVolume(FoodResp, 4, () => {
          switchGif(IdleAlienGif);
          // Its the tutorial so say the command

          if (message.memories) {
            setMemories(message.memories);
          }

          let mems = message.memories || memories;

          buttonIncreaseFill(false, true, mems);
          setTimeout(() => {
            switchGif(TalkingGif1);
            setTextBoxListItems([
              {
                user: "alien",
                message:
                  `${CRYSTAL_SPEECH}`,
              },
            ]);
            audioFunctions.playWithIncreasedVolume(
              UnlockIntro,
              3,
              () => {
                setTimeout(() => {
                  switchGif(IdleAlienGif);
                }, 2000) 
              
                promptUser();
              },
              true,
            );
          }, 1500);
        }, true);
      });
    }

    if (message.ANIMAL_SPEECH) {
      // Before we play animal speech we need to contact the mothership
      switchGif(IdleAlienGif);
      // dropTheDiamonds()

      // if (message.MEMORY) {
      //   setMemories(
      //     [
      //       ...memories,
      //       message.MEMORY,
      //     ]
      //   )
      // }

      setTextBoxListItems([
        ...textBoxListItems,
        {
          user: "player",
          message: capitalizeFirstLetter(message.OUR_TEXT),
        },
      ]);

      audioFunctions.playWithIncreasedVolume(ContactMotherShipVo, 1, () => {
        switchGif(TalkingGif1, true);

        // LEts check if they have a memory
        let gdObj = {
          ...gameData,
          messages: gameData.messages - 1,
        };

        if (message.memories) {
          setMemories(message.memories);
          gdObj.memories = message.memories;
        }

        setGameData(gdObj);
        pulseButton();
        setTextBoxListItems([
          ...textBoxListItems,
          {
            user: "player",
            message: capitalizeFirstLetter(message.OUR_TEXT),
          },
          {
            user: "alien",
            message: capitalizeFirstLetter(message.ANIMAL_TEXT),
          },
        ]);
        audioFunctions.playWithIncreasedVolume(
          message.ANIMAL_SPEECH,
          4,
          () => {
            let mems = message.memories || memories;
            let sendObj = buttonIncreaseFill(true, false, mems);

            setTimeout(() => {
              switchGif(IdleAlienGif);
            }, 2000)
            promptUser({
              FOOD_INTRO: true,
              ...sendObj,
            });
          },
          true,
        );
      }, true);
    }

    if (message.PROFANITY) {
      let randomProfanity = getRandomElement([Language1, Language2, Language3]);
      switchGif(TalkingGif1, true);

      audioFunctions.playWithIncreasedVolume(
        randomProfanity,
        4,
        () => {
          increaseFill(true);
          setTimeout(() => {
            switchGif(IdleAlienGif);
          }, 2000) 
          promptUser();
        },
        true,
      );
    }
  }, [message]);

  return (
    <div className="splash-screen">
      
      {showLoading && <LoadingScreen />}
      {showGamePlay && (
        <>
          <img src={MoonImage} draggable="false" alt="Moon" className="moon-image" />

          {toggleUpsell ? (
            <UpsellModal setUpsellModal={setUpsellModal} locale={gameData?.locale} />
          ) : (
            <>
              <DropTheFood ref={dropFoodRef} />
              {/* <DiscoBall ref={discoBallRef} /> */}
              <NotificationText ref={notifRef} />

              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "25vw", position: "fixed", top: "20vh", right: "5vh", zIndex: "9999" }}></div>

              <div className="alien-container" onClick={() => pressPoke(false)}>

           
                   <AlienAnim ref={alienAnimRef} initialGif={IdleAlienGif} />
              </div>
            </>
          )}

          <div id="screen-settings-container">
            <div id="settings-label">
              <span style={{ fontSize: "3vh" }}>x</span>
              {gameData.messages || 0}
            </div>

            <button className="settings-button">
              <img src={Diamond} onClick={toggleModal} draggable="false" alt="Settings" />
            </button>
          </div>

          <HappyBar />


          <DayContainer currLevel={currLevel} />

          {doLoading && (
            <div id="loading-wrapper" onClick={() => setDoLoading(false)}>
              <Loading isLoading={doLoading} />
            </div>
          )}


          <audio id="generic-audio" src=""></audio>

          <TextBoxList textBoxItems={textBoxListItems}></TextBoxList>
          {showMemoryModal && (
            <MemoriesModal toggleModal={setToggleMemoryModal} showModal={showMemoryModal} memories={memories}></MemoriesModal>
          )}


          <button className="settings-button right">
            <img src={BrainBtn} onClick={doMemoryToggle} draggable="false" alt="Settings" />
          </button>
        </>
      )}
    </div>

  );
};

export default SplashScreen;
