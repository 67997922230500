import React, { useState, useImperativeHandle, forwardRef } from 'react';

const NotificationText = forwardRef((props, ref) => {
    const [setShowing, setShowNotification] = useState(false);
    const [setHiding, setHideNotification] = useState(false);

    useImperativeHandle(ref, () => ({
        showNotif: () => {
            setHideNotification(false); // Ensure moving up state is reset
            setShowNotification(true);
            setTimeout(()=> {
                setHideNotification(true); // Ensure moving up state is reset
                setShowNotification(false);
            }, 2000)
        },
      }), []);

    return (
            <div className={`notification-text-container ${setShowing ? 'animate-popup' : ''} ${setHiding ? 'animate-pop-down' : ''}`}>
                +10
        </div>
    );
});

export default NotificationText;
