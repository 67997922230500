import React, { useContext } from 'react';
import blueContainer from '../img/assets/new-modals/big-blue.png';
import redCross from '../img/assets/new-modals/red-cross.png';
import { AlexaContext } from '../utils/AlexaService';


const MemoryItem = ({ memoryObj = {}}) => {
  const { message, gameData, setGameData, setMessage} = useContext(AlexaContext);


  const deleteMemory = (event, memoryId) => {
    handlePress(event);
    let updatedMemories = gameData.memories.filter((x) => x.id != memoryId);
    setGameData(
      {
        ...gameData,
        memories: updatedMemories,
      }
    );

    // Actually lets send the whole list incase they spam the memories

    

    window.sendMessage(
      {
        "UPDATE_MEMORIES": updatedMemories,
      }
    )
  }

    const handlePress = (event) => {
        const button = event.currentTarget;
        button.classList.add('alien-action-button-pressed');
      
        setTimeout(() => {
          button.classList.remove('alien-action-button-pressed');
        }, 200); // Duration to match the CSS transition
      };

    return (
        <div className='memory-memoryItem'>
            <div className="memoryItem-wrapper">
                <img className="memory-blueContainer" src={blueContainer} alt="Blue Container"/>
                <img className="memory-redCross" src={redCross} alt="Red Cross" onClick={(e) => deleteMemory(e, memoryObj.id)}/>
                <div className="memory-text">{memoryObj.memory}</div>
            </div>
        </div>
    );
  };
  

export default MemoryItem;
